import gql from 'graphql-tag';

export const WISTIA_THUMBNAIL = gql`
	query WistiaThumbnail($id: String!) {
		wistiaVideoData(hash: $id) {
			thumbnail {
				url
			}
		}
	}
`;
